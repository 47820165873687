<script setup>
  import {useMenuStore} from "@/store/MenuStore";
  import {storeToRefs} from "pinia";
  import {useCartStore} from "@/store/CartStore";
  import {useAuthStore} from "@/store/AuthStore";
  import {getCurrencySymbol} from "@/helpers";

  const menuStore = useMenuStore();
  const cartStore = useCartStore();
  const authStore = useAuthStore();
  const {items} = storeToRefs(cartStore)
  const {allProducts, currentCity} = storeToRefs(menuStore)
  const {isAuthenticated} = storeToRefs(authStore)

  const handleAddToCart = async (product) => {
    const addProduct = async () => {
      const productFromCart = items.value.find((item) => {
        return item.product.id === product.id;
      });
      if (productFromCart) {
        await cartStore.cartItemUpdate(productFromCart.id, productFromCart.quantity + 1);
      } else {
        await cartStore.addToCart({...product, quantity: 1});
      }
    }

    if (!isAuthenticated.value) {
      authStore.setPostAuthAction(addProduct)
      authStore.openLoginModal();
      return;
    }

    await addProduct();
  }
</script>

<template>
  <v-autocomplete
    :items="allProducts"
    density="compact"
    menu-icon=""
    :placeholder="$t('search')"
    prepend-inner-icon="mdi-magnify"
    width="100%"
    max-width="245"
    theme="light"
    variant="solo"
    auto-select-first
    item-props
    rounded
    flat
    hide-details
    class="search"
    :list-props="{class: 'pa-4'}"
  >
    <template #item="{ props }">
      <v-sheet
        v-bind="props"
        class="listitem pa-2 d-flex flex-row justify-center align-center ga-3"
      >
        <v-sheet class="bg-transparent">
          <v-img
            width="70"
            height="43"
            :src="props.image_full_url"
          />
        </v-sheet>
        <v-sheet
          class="bg-transparent flex-1-0-0"
          max-width="160"
        >
          {{ props.title }}
        </v-sheet>
        <v-sheet
          class="bg-transparent"
        >
          <v-btn
            class="rounded-xl text-uppercase semibold currency-symbol"
            color="#e31d24"
            @click="() => handleAddToCart(props)"
          >
            {{ props.price }}&nbsp;{{ getCurrencySymbol(currentCity) }}
          </v-btn>
        </v-sheet>
      </v-sheet>
    </template>
  </v-autocomplete>
</template>

<style scoped>
.search{
  min-width: 245px;
  border: 1px solid #e0e0e0;
  border-radius: 40px;
}
.listitem {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 6px;
  &:hover {
    background: rgba(227, 21, 23, 0.1);
  }
}
</style>
