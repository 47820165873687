import {createApp, h, provide} from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "@/router";
import { createPinia } from "pinia";
import { mask } from "vue-the-mask";
import {DefaultApolloClient} from "@vue/apollo-composable";
import apolloClient from "../apolloClient";
import i18n from "@/i18n";

void loadFonts();
const pinia = createPinia();
const app = createApp({
    setup() {
        // Здесь мы добавляем ApolloClient только после того, как Pinia была зарегистрирована
        provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App),
});

app
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .directive("mask", mask)
  // .use(VueTheMask)
  .mount("#app");
