import { gql } from "@apollo/client/core";
import {CART_ITEMS_FIELDS, CITY_FIELDS, ORDER_FIELDS, PRODUCT_FIELDS} from "@/api/fragments";

export const GET_SECTIONS = gql`
  query GetProducts {
    products {
      id
      name
      price
    }
  }
`;

export const CATEGORIES = gql`
  ${PRODUCT_FIELDS}
  query categories($scope: CategoryScope, $city_id: ID, $version: String, $locale: String) {
    categories(scope: $scope, locale: $locale) {
      id
      title
      priority
      active
      scope
      image_url
      products(city_id: $city_id, version: $version) {
        ...ProductFields
      }
    }
  }
`;

export const CITIES = gql`
  ${CITY_FIELDS}
  query cities {
    cities {
      ...CityFields
    }
  }
`;

export const VERIFICATION_CODE = gql`
  mutation verificationCode($phone_number: String!, $device_name: String!) {
    verificationCode(phone_number: $phone_number, device_name: $device_name) {
      verification_code_id
      is_new_user
    }
  }
`;

export const AUTHORIZATION_TOKEN = gql`
  mutation login(
    $verification_id: String!
    $verification_code: String!
    $city_id: ID
    $name: String
    $items: [InputItem]
  ) {
    login(
      verification_id: $verification_id
      verification_code: $verification_code
      city_id: $city_id
      name: $name
      items: $items
    ) {
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

export const PROFILE_DELETE = gql`
  mutation profileDelete {
    profileDelete {
      success
    }
  }
`;

export const CART_ITEM_CREATE = gql`
  ${CART_ITEMS_FIELDS}
  mutation cartItemCreate(
    $product_id: ID!
    $quantity: Int!
    $modifier_items: [ID!]
    $extra_ingredients: [CartIngredient!]
    $part_remove_ingredients: [ID!]
  ) {
    cartItemCreate(
      product_id: $product_id
      quantity: $quantity
      modifier_items: $modifier_items
      extra_ingredients: $extra_ingredients
      part_remove_ingredients: $part_remove_ingredients
    ) {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`;

export const CART = gql`
  ${CART_ITEMS_FIELDS}
  query cart(
    $locale: String
  ) {
    cart(
    locale: $locale
    ) {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`;

export const USER = gql`
  ${CITY_FIELDS}
  query me {
    me {
      id
      name
      last_name
      birthday
      gender
      notify_via_phone
      notify_via_email
      avatar_url
      email
      phone_number
      created_at
      city {
        ...CityFields
      }
    }
  }
`

export const WORK_SCHEDULE_PICKUP = gql`
  query GetWorkSchedulePickup($city_address_id: ID!) {
    workSchedulePickup(city_address_id: $city_address_id) {
      date
      hours
    }
  }
`

export const WORK_SCHEDULE_DELIVERY = gql`
  query GetWorkScheduleDelivery($address: WorkScheduleDeliveryAddress!) {
    workScheduleDelivery(address: $address) {
      date
      hours
    }
  }
`

export const GET_ORDERS = gql`
  ${ORDER_FIELDS}
  query getOrders($first: Int = 20, $page: Int, $active: Boolean) {
    orders(first: $first, page: $page, active: $active) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        ...OrderFields
      }
    }
  }
`;

export const WORK_SCHEDULE_HOURS = gql`
  query workScheduleHours($city_id: ID!) {
    workScheduleHours(city_id: $city_id) {
      id
      title
      hours
      type
    }
  }
`;
