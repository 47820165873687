<script setup>
import BaseAlert from "@/components/base/BaseAlert.vue";
import {onBeforeMount} from "vue";
import {useAuthStore} from "@/store/AuthStore";
import LoginModal from "@/components/Auth/LoginModal.vue";
import {useMenuStore} from "@/store/MenuStore";
import {createRoutesForCities} from "@/router";
import Cookies from "js-cookie";
import {COOKIE_LANGUAGE_NAME} from "@/constants/cookies";
import {useI18n} from "vue-i18n";
import BaseToast from "@/components/base/BaseToast.vue";
import {storeToRefs} from "pinia";
import {useCartStore} from "@/store/CartStore";


const authStore = useAuthStore();
const menuStore = useMenuStore();
const { locale } = useI18n();
const {isAuthenticated} = storeToRefs(authStore);
const cartStore = useCartStore();

onBeforeMount(async () => {
  try {
    const language = Cookies.get(COOKIE_LANGUAGE_NAME);
    if (language) {
      locale.value = language;
    } else {
      locale.value = 'ru';
      Cookies.set(COOKIE_LANGUAGE_NAME, 'ru');
    }

    await menuStore.getCities();
    await createRoutesForCities(menuStore.cities)
    await authStore.checkAuth();

    if (isAuthenticated.value) {
      await cartStore.getCart(locale.value);
      await menuStore.setCurrentCity(authStore.user.city);
    }
  } catch (error) {
    console.error(error);
  }
});

</script>

<template>
  <v-app>
    <v-main
      v-if="menuStore.cities.length > 0"
      class="pa-0"
    >
      <router-view />
    </v-main>
    <BaseAlert />
    <BaseToast />
    <LoginModal />
  </v-app>
</template>

<style lang="scss">
@import "@/assets/fonts/font.css";

* {
  font-family: "Proxima Nova", sans-serif !important;
}
.semibold {
  font-family: "Proxima Nova semibold", sans-serif !important;
}

.bold {
  font-family: "Proxima Nova bold", sans-serif !important;
}

.regular {
  font-family: "Proxima Nova regular" !important;
}

.currency-symbol {
  font-family: 'Noto Sans', sans-serif !important;
}

.fullscreen-skeleton {
  width: 100vh;
  min-height: 100vh;
  height: 100vh !important;
  div {
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.3) !important;
  }
}

.swiper-pagination-bullet-active {
  background: red!important;
}

</style>
