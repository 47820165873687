<template>
  <v-btn
    flat
    size="x-small"
    @click.stop="menu = !menu"
  >
    <v-sheet class="bg-transparent mr-1 semibold text-uppercase">
      {{ $t('menu') }}
    </v-sheet>
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
  <v-navigation-drawer
    v-model="menu"
    theme="white"
  >
    <template #prepend>
      <v-sheet class="d-flex justify-center align-center pt-5 semibold text-grey-lighten-1 text-uppercase">
        {{ $t('menu') }}
      </v-sheet>
    </template>
    <v-list nav>
      <v-list-item
        v-for="(item, index) in menuStoreData.sectionsToShow"
        :key="index"
        class="d-flex flex-row align-center ga-4 border-b-sm mb-0 py-2"
        :class="{
          'bg-customRed': menuStoreData.activeSection === item.id,
        }"
        @click="handleItemClick(item)"
      >
        <template #prepend>
          <v-sheet class="d-flex justify-center align-center ">
            <v-img
              cover
              width="130"
              height="56"
              :src="item.image_url"
              rounded="lg"
            />
          </v-sheet>
        </template>
        <v-list-item-title class="semibold">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { ref} from 'vue';
import { useRouter } from 'vue-router';
import {useMenuStore} from "@/store/MenuStore";

const isMenuOpen = ref(false); // Управление состоянием меню
const icon = ref("mdi-menu"); // Иконка для кнопки, можно заменить
const menuStoreData = useMenuStore();
const router = useRouter();
const menu = ref(false)

const handleItemClick = (item) => {
  router.push({
    hash: `#${item.id}`,
  });
  isMenuOpen.value = true;
};
</script>

<style scoped>
.v-list-item-title {
  cursor: pointer;
}
</style>
