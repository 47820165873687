<template>
  <v-sheet class="d-flex flex-column align-items-start px-2">
    <div class="text-subtitle-1 text-uppercase">
      {{ $t('workingHours') }}
    </div>
    <v-sheet
      v-if="currentCityWorkHours !== null && currentCityWorkHours[0] !== undefined"
      class="bg-transparent pt-2"
    >
      <v-sheet
        v-for="hour in currentCityWorkHours[0].hours"
        :key="hour"
        class="bg-transparent"
      >
        {{ hour }}
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script setup>
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";

const {currentCityWorkHours} = storeToRefs(useMenuStore());

</script>
