export const RU_GENDERS = [
    {
        title: 'Мужской',
        value: 'MALE',
    },
    {
        title: 'Женский',
        value: 'FEMALE',
    },
    {
        title: 'Другой',
        value: 'ANOTHER',
    }
];

export const EN_GENDERS = [
    {
        title: 'Male',
        value: 'MALE',
    },
    {
        title: 'Female',
        value: 'FEMALE',
    },
    {
        title: 'Another',
        value: 'ANOTHER',
    }
];

export const getGenders = (locale) => {
    switch (locale) {
        case 'ru':
            return RU_GENDERS;
        case 'en':
            return EN_GENDERS;
        default:
            return RU_GENDERS;
    }
}
